import React, { useEffect, useState } from "react";
import {Col, Container, Row} from 'react-grid-system';
import DataTable from "./common/dataTable";
import {Link, useParams} from "react-router-dom";
import API from "../services/api";
import { toast, ToastContainer } from "react-toastify";


const ProductsList = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(false)
  const [currentFromKey, setCurrentFromKey] = useState(false)
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [nextFromKey, setNextFromKey] = useState(false)

  const {importId} = useParams();

  useEffect(() => {
    setLoading(true)
    API.getProducts(importId, rowsPerPage, currentFromKey, toast).then(response => {
      if (response.data?.errors) {
        console.log(response.data.errors);
      } else {
        setProducts(response.data.data.Items)
      }
    }).finally(() => setLoading(false))
  }, [])

  return (
  <div className="pageContent">
      <ToastContainer />
      <Container>
        <Row>
          <Col offset={{lg: 10}} lg={11}>
              <div className="btn btn-primary">
                Create import
                <Link className="stretched-link" to="/product-uploader" />
              </div>
          </Col>
          <Col offset={{lg: 1}} lg={10}>
              <DataTable
                title="Products"
                progressPending={loading}
                onChangePage={setCurrentFromKey}
                keyField="SK"
                paginationServer={true}
                onChangeRowsPerPage={setRowsPerPage}
                rowsPerPage={rowsPerPage}
                nextFromKey={nextFromKey}
                striped={true}
                noHeader={true}
                columns={[
                  {
                    name: 'Created at',
                    cell: row => (new Date(row.createdAt).toLocaleString()),
                  },
                  {
                    name: 'Status',
                    cell: row => row.productStatus,
                  },
                  {
                    name: 'Title',
                    cell: row => row.title,
                  },
                  {
                    name: 'Sku',
                    cell: row => row.sku,
                  },
                  {
                    name: 'Barcode',
                    cell: row => row.barcode,
                  },
                ]}
                data={products}
              />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProductsList
