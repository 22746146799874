import React, { useState, useEffect } from "react";
import Joi from "joi";
import API from "../services/api";
import { toast } from "react-toastify";
import _ from "lodash";

export const UploadValidation = (props) => {
  const [data, setData] = useState([]);
  const [processButtonEnabled, setProcessButtonEnabled] = useState(true);
  const [schema, setSchema] = useState(Joi.object(props.schema));
  const [errors, setErrors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [searchQuery, setSearchQuery] = useState("");
  const [selektedGenre, setSelectedGenre] = useState(null);
  const [sortColumn, setSortColumn] = useState({ path: "title", order: "asc" });

  useEffect(async () => {
    await initData();
    await validateData();
  }, []);

  const initData = async () => {
    setData(props.data);
  };

  const getPagedData = () => {
    return { totalCount: data.length, data };
  };

  const validate = (data) => {
    const options = {
      abortEarly: false,
    };
    // const result = Joi.validate(data, schema, options);
    const result = schema.validate(data, {
      allowUnknown: true,
    })
    const { error } = result;
    if (!error) return null;

    const errors = {};

    error.details.forEach((item) => {
      errors[item.path[0]] = item.message;
    });

    return errors;
  };

  const validateProperty = async ({ name, value }) => {
      const obj = { [name]: value };
      const schema = { [name]: schema[name] };
      let barcodeValidationMsg;
      let { error } = Joi.validate(obj, schema);
      if (value && (name === "barcode" || name === "Variant Barcode")) {
        const valid = await API.validateBarcode(value, toast);
        if (!valid) {
          barcodeValidationMsg =
            'The following barcode type will default to "Other" as the barcode is invalid: ' +
            value;
          return{
            message: barcodeValidationMsg,
            type: "warning",
            identifier: "barcode",
          }
        }
      }

      return error
          ? {
              message: error.details[0].message,
              type: "error",
            }
          : null
  };


  const validateData = async () => {
    console.log("Validating data..");

    for (const [i, record] of data.entries()) {
      const errorMessages = this.state.errors;
      if (errorMessages.length > 10) {
        break
      }

      const errors = this.validate(record)
      if (errors) {
        errors.product = record["Variant SKU"] ? record["Variant SKU"] : "No product detected"
        setErrors([...errorMessages, errors]);
      }

      // for (const value of Object.entries(record)) {
      //   const input = {
      //     name: value[0].trim(),
      //     value: value[1],
      //   };
      //   console.log("Input: ", input);
      //   const errorMessages = this.state.errors;
      //   let validation;
      //   try {
      //     validation = await this.validateProperty(input).catch(error => {
      //       console.log("Error validating:")
      //       console.log(error)
      //     });
      //     console.log("VALIDATION: ", validation);
      //   } catch (ex) {
      //     validation = ex;
      //     console.log(
      //       "An exception has occurred while validating the property: ",
      //       ex
      //     );
      //   }
      //   if (validation !== null && !_.isEmpty(validation)) {
      //     validation.product = record["Handle"];
      //     this.setState({ errors: [...errorMessages, validation] });
      //
      //     if (
      //       !_.isEmpty(validation.identifier) &&
      //       validation.identifier === "barcode"
      //     ) {
      //       data[i]["barcode_validity"] = false;
      //     }
      //   }
      //   console.log("DATA: ", data);
      // }
    }
  };

  const onSubmit = async () => {
    setProcessButtonEnabled(false);
    props.updateData(data);
    props.updateStep({ id: 4, name: "Data Processing" });
  };

  const retryUpload = () => {
    const step = {
      id: 1,
      name: "Upload CSV",
    };

    const data = [];
    props.updateData(data);
    props.updateStep(step);
  };

  const { length: count } = data.length;
  const validationColorMappings = {
    error: "red",
    warning: "orange",
  };

  if (count === 0) return <p>There are no rows in the csv.</p>;

  return (
    <React.Fragment>
      <div className="row justify-content-center">
        <div className={"col-3"}></div>
        <div className="col-6">
          <h6 className="justify-text-center">Validation Alerts</h6>
          <ul>
            {errors.length ? (
              errors.map((errorsData, index) => {
                return Object.keys(errorsData).map((errorField, errorIndex) => {
                  if (errorField !== 'product') {
                    return <li
                    key={index + errorIndex}
                    style={{ color: validationColorMappings['error'] }}
                      >
                      {errorField}: {errorsData[errorField]}
                      <br />
                      <span>Product: {errorsData.product}</span>
                      </li>
                  }
                })
              })
            ) : (
              <span>There are 0 validation errors.</span>
            )}
          </ul>
        </div>
        <div className={"col-3"}></div>
      </div>
      <div className="row justify-content-center">
        <div className="col-4">
          {errors.length == 0 && 
            <button
              type="button"
              disabled={!processButtonEnabled}
              className={`btn-primary btn`}
              onClick={onSubmit}
            >
              Process Products
            </button>
          }
        </div>
        <div className="col-4"></div>
        <div className="col-4">
          {errors.length > 1 && (
            <button
              type="button"
              className={"btn-primary btn"}
              onClick={retryUpload}
            >
              Retry Upload
            </button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default UploadValidation;
